<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Visualizar Produto</h4>
          <div class="page-title-right">
            <b-button
              variant="warning"
              @click.prevent="
                $router.push({
                  name: 'produto-edit',
                  params: { id: $route.params.id },
                })
              "
            >
              <i class="fas fa-edit mr-0 mr-md-2"></i>
              <span class="d-none d-md-inline-block">Editar Produto</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="info"
              @click.prevent="$router.push({ name: 'produtos' })"
            >
              <i
                class="bx bx-list-ol font-size-16 align-middle mr-0 mr-md-2"
              ></i>
              <span class="d-none d-md-inline-block">Listar Produtos</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="success"
              @click.prevent="$router.push({ name: 'produto' })"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-0 mr-md-2"></i>
              <span class="d-none d-md-inline-block">Adicionar</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <span class="mb-2 d-block">Erro ao carregar dados!</span>
              <img width="300" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="product != null">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-xl-6">
                  <div class="product-detai-imgs">
                    <b-tabs
                      pills
                      vertical
                      nav-wrapper-class="col-md-2 col-sm-3 col-4"
                    >
                      <span v-if="product.image != null && product.image != ''">
                        <b-tab v-for="image in product.image" :key="image.id">
                          <template v-slot:title>
                            <img
                              :src="image.image300"
                              alt
                              class="img-fluid mx-auto d-block tab-img rounded"
                            />
                          </template>
                          <div class="product-img">
                            <img
                              :src="image.image500"
                              alt
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </b-tab>
                      </span>
                      <b-tab v-else>
                        <template v-slot:title>
                          <img
                            src="@/assets/images/notpro.jpg"
                            alt
                            class="img-fluid mx-auto d-block tab-img rounded"
                          />
                        </template>
                        <div class="product-img">
                          <img
                            src="@/assets/images/notpro.jpg"
                            alt
                            class="img-fluid mx-auto d-block"
                          />
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>

                <div class="col-xl-6 text-left">
                  <div class="mt-3">
                    <h3 class="mt-1 mb-1">{{ product.name }}</h3>
                    <p class="text-muted float-left mr-3 font-size-16">
                      <span
                        class="bx bx-star text-warning"
                        v-for="i in product.start"
                        :key="i"
                      ></span>
                      <span
                        v-for="i in (5 - product.start)"
                        :key="i + product.start"
                        class="bx bx-star ml-1"
                      ></span>
                    </p>
                    <p class="text-muted mb-4">
                      ( {{ product.cont }}
                      {{ product.cont > 1 ? 'avaliações' : 'avaliação' }} )
                    </p>

                    <h1
                      class="mb-4"
                      v-if="
                        dataPromo(
                          product.discount_date_start,
                          product.discount_date_end,
                        )
                      "
                    >
                      <span class="text-muted mr-2">
                        <del>{{ product.price | currency('R$ ')}}</del>
                      </span>
                      <b>{{ product.discount_price | currency('R$ ')}}</b>
                    </h1>
                    <h1 class="mb-4" v-else>
                      <b>{{ product.price | currency('R$ ')}}</b>
                    </h1>

                    <h6 class="mb-1">
                      <strong>Status:</strong>
                      {{ product.status == 0 ? 'Ativo' : 'Bloqueado' }}
                    </h6>
                    <h6 class="mb-1">
                      <strong>Categoria:</strong>
                      {{ product.category.name }}
                    </h6>
                    <h6 class="mb-1">
                      <strong>Marca:</strong>
                      {{ product.brand.brand.name }}
                    </h6>
                    <h6 class="mb-2">
                      <strong>Fabricante:</strong>
                      {{ product.manufacturer.manufacturer.name }}
                    </h6>
                    <h6 class="mb-2">
                      <strong>Digital:</strong>
                      {{ product.digital == 0 ? 'Não' : 'Sim' }}
                    </h6>
                    <h6 class="mb-4">
                      <strong>Estoque:</strong>
                      {{ product.stock }}
                    </h6>

                    <p class="text-muted mb-4">
                      {{ product.description_short }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-tabs pills justified content-class="p-3 text-muted">
                <b-tab active class="border-0">
                  <template v-slot:title>
                    <span>Descrição</span>
                  </template>
                  <div
                    class="row py-3 border-top description-product"
                    v-html="product.description_long"
                  ></div>
                </b-tab>
                <b-tab class="border-0">
                  <template v-slot:title>
                    <span>Características</span>
                  </template>
                  <div
                    class="row py-3 border-top description-product"
                    v-html="product.characteristics"
                  ></div>
                </b-tab>
                <b-tab class="border-0">
                  <template v-slot:title>
                    <span>Avaliações</span>
                  </template>

                  <div class="row" v-if="ratings.length <= 0">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-body py-5">
                          <h5 class="mb-3">Nenhum item encontrado!</h5>
                          <img width="250" src="@/assets/images/not-data.svg" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row py-3 border-top text-left" v-else>
                    <ul class="list-group list-group-flush w-100">
                      <li
                        class="list-group-item py-3"
                        v-for="data of ratings"
                        :key="data.id"
                      >
                        <div class="d-flex">
                          <div class="mr-3">
                            <img
                              v-if="
                                data.customer.avatar &&
                                data.customer.avatar != null &&
                                data.customer.avatar != ''
                              "
                              :src="data.customer.avatar"
                              alt=""
                              class="avatar-md h-auto d-block rounded"
                            />
                            <img
                              v-else
                              src="@/assets/images/notimg.jpg"
                              alt=""
                              class="avatar-md h-auto d-block rounded"
                            />
                          </div>
                          <div
                            class="align-self-center overflow-hidden mr-auto"
                          >
                            <div>
                              <h5 class="font-size-14 text-dark">
                                {{ data.customer.name }}
                              </h5>
                              <p class="text-muted mb-2">
                                <i
                                  class="fas fa-star text-warning mr-1"
                                  v-for="index in data.rating"
                                  :key="index"
                                ></i>
                                <i
                                  class="fas fa-star text-muted mr-1"
                                  v-for="index in (5 - data.rating)"
                                  :key="index + data.rating"
                                ></i>
                                ({{ data.rating }})
                              </p>
                              <p class="text-muted mb-2">
                                {{ data.description }}
                              </p>
                              <p>
                                <span
                                  class="badge"
                                  :class="{
                                    'badge-success': `${data.status}` == 1,
                                    'badge-info': `${data.status}` == 0,
                                    'badge-danger': `${data.status}` == 2,
                                  }"
                                >
                                  {{
                                    data.status == 0
                                      ? 'Pendente'
                                      : data.status == 1
                                      ? 'Aprovado'
                                      : 'Recusado'
                                  }}
                                </span>
                              </p>
                              <b-button
                                @click.prevent="ac(data, 1)"
                                variant="success"
                                v-if="data.status != 1"
                              >
                                <i class="fas fa-thumbs-up mr-0 mr-md-2"></i>
                                <span class="d-none d-sm-inline-block">
                                  Aprovar
                                </span>
                              </b-button>
                              <b-button
                                @click.prevent="ac(data, 2)"
                                variant="danger"
                                :class="data.status == 0 ? 'ml-2' : ''"
                                v-if="data.status != 2"
                              >
                                <i class="fas fa-thumbs-down mr-0 mr-md-2"></i>
                                <span class="d-none d-sm-inline-block">
                                  Recusar
                                </span>
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <infinite-loading @infinite="infiniteHandler">
                    <div slot="spinner" class="text-info"></div>
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                  </infinite-loading>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      product: null,
      loading: true,
      error: false,
      postDataAvaliacoes: [],
      filtro: {
        product_id: null,
        limit: 10,
        page: 1,
      },
      ratings: [],
    }
  },
  created() {
    if (this.$route.params.id > 0) {
      this.findProduct()
      this.getRatings()
    } else {
      this.loading = false
      this.error = true
    }
  },
  methods: {
    ...mapActions('product', ['ActionProductFind']),
    ...mapActions('rating', ['ActionRatingAll', 'ActionRatingUpdate']),
    async findProduct() {
      try {
        await this.ActionProductFind(this.$route.params.id).then((res) => {
          this.product = res.data.data
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.loading = false
        this.error = true
        this.product = null
      }
    },
    async infiniteHandler($state) {
      try {
        this.filtro.product_id = this.$route.params.id
        if (this.$route.params.id <= 0) {
          this.ratings = []
          $state.complete()
        } else {
          await this.ActionRatingAll(this.filtro).then((res) => {
            if (res.data.data.list.length) {
              this.filtro.page += 1
              this.ratings.push(...res.data.data.list)
              $state.loaded()
            } else {
              $state.complete()
            }
          })
        }
      } catch (_) {
        this.ratings = []
        $state.complete()
      }
    },

    async getRatings() {
      try {
        this.filtro.product_id = this.$route.params.id
        this.filtro.page = 1
        await this.ActionRatingAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.ratings = res.data.data.list
          }
        })
      } catch (_) {
        this.ratings = []
      }
    },

    dataPromo(start, end) {
       var dia = new Date()
      var d = new Date(start)
      var df = new Date(end)
      if(d <= dia){
          if(dia <= df){
              return true
          }
          return false
      }
    },
    async ac(data, ac) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando alterações',
          icon: 'info',
          showConfirmButton: false,
        })
        data.status = ac
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionRatingUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.showModal = false
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
  },
}
</script>
